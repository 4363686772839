import { useAuth0 } from "@auth0/auth0-react";
import React, { ComponentPropsWithoutRef, FC, lazy, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { useMe } from "../queries/me";
import { useAnalytics } from "../utils/analytics";
import retry from "../utils/retry";
import { Routes } from ".";
import { ProtectedRoute } from "./ProtectedRoute";

const lazyWithMore = (
  factory: () => Promise<{ default: FC }>
): ReturnType<typeof lazy> & {
  preload: () => ReturnType<typeof retry>;
} => {
  const Component = lazy(() => retry(factory));
  // @ts-ignore
  Component.preload = () => retry(factory);
  // @ts-ignore
  return Component;
};

const ALSClinics = lazyWithMore(
  () => import("../ui/pages/ALSClinics/ALSClinicsPage")
);
const Biomarkers = lazy(() =>
  retry(() => import("../ui/pages/Biomarkers/Biomarkers"))
);
const ClinicalTrials = lazyWithMore(
  () => import("../ui/pages/ClinicalTrials/ClinicalTrials")
);
const ClinicalTrialsForPD = lazyWithMore(
  () => import("../ui/pages/ClinicalTrialsForPD/ClinicalTrialsForPD")
);
const Home = lazy(() => retry(() => import("../ui/pages/Home/Home")));
const Messaging = lazy(() =>
  retry(() => import("../ui/pages/Messaging/MessagingPage"))
);
const Genetics = lazyWithMore(() => import("../ui/pages/Genetics/Genetics"));
const GeneticsForPD = lazyWithMore(
  () => import("../ui/pages/GeneticsForPD/GeneticsForPD")
);
const InsuranceNavigation = lazyWithMore(
  () => import("../ui/pages/InsuranceNavigation/InsuranceNavigationPage")
);
const Login = lazy(() => retry(() => import("../ui/pages/Login/Login")));
const Nutraceutical = lazyWithMore(
  () => import("../ui/pages/Nutraceutical/NutraceuticalPage")
);
const NutraceuticalForPD = lazyWithMore(
  () => import("../ui/pages/NutraceuticalForPD/NutraceuticaForPD")
);
const Onboarding = lazy(() =>
  retry(() => import("../ui/pages/Onboarding/Onboarding"))
);
const Profile = lazy(() => retry(() => import("../ui/pages/Profile/Profile")));
const Teleneurology = lazyWithMore(
  () => import("../ui/pages/Teleneurology/TeleneurologyPage")
);
const TherapiesAndTrials = lazy(() =>
  retry(() => import("../ui/pages/TherapiesAndTrials/TherapiesAndTrials"))
);
const PatientLayout = lazy(() =>
  retry(() => import("../ui/templates/PatientLayout/PatientLayout"))
);

const WorldClassMovementDisorderClinics = lazyWithMore(
  () =>
    import(
      "../ui/pages/WorldClassMovementDisorderClinics/WorldClassMovementDisorderClinics"
    )
);

const AppRoutes: FC = () => {
  const { identify } = useAnalytics();
  const { isAuthenticated } = useAuth0();
  const me = useMe(isAuthenticated);
  useEffect(() => {
    if (me?.me?.external_id) {
      identify(me.me.external_id);
    }
  }, [identify, me?.me?.external_id]);

  if (isAuthenticated) {
    ALSClinics.preload();
    ClinicalTrials.preload();
    ClinicalTrialsForPD.preload();
    Genetics.preload();
    GeneticsForPD.preload();
    InsuranceNavigation.preload();
    Nutraceutical.preload();
    NutraceuticalForPD.preload();
    Teleneurology.preload();
    WorldClassMovementDisorderClinics.preload();
  }

  return (
    <Switch>
      <ProtectedPatientRoute
        content={<ALSClinics />}
        path={Routes.ALSClinics}
      />
      <ProtectedPatientRoute
        content={<WorldClassMovementDisorderClinics />}
        path={Routes.MovementDisordersClinics}
      />
      <ProtectedPatientRoute
        content={<Biomarkers />}
        path={Routes.Biomarkers}
      />
      <ProtectedPatientRoute
        content={<ClinicalTrials />}
        path={Routes.ClinicalTrials}
      />
      <ProtectedPatientRoute
        content={<ClinicalTrialsForPD />}
        path={Routes.ClinicalTrialsForPD}
      />
      <ProtectedPatientRoute content={<Genetics />} path={Routes.Genetics} />
      <ProtectedPatientRoute
        content={<GeneticsForPD />}
        path={Routes.GeneticsForPD}
      />
      <ProtectedPatientRoute content={<Home />} path={Routes.Home} />
      <ProtectedPatientRoute
        content={<InsuranceNavigation />}
        path={Routes.InsuranceNavigation}
      />
      <ProtectedPatientRoute content={<Messaging />} path={Routes.Messaging} />
      <ProtectedPatientRoute
        content={<Nutraceutical />}
        path={Routes.Nutraceutical}
      />
      <ProtectedPatientRoute
        content={<NutraceuticalForPD />}
        path={Routes.NutraceuticalForPD}
      />
      <ProtectedPatientRoute content={<Profile />} path={Routes.Profile} />
      <ProtectedPatientRoute
        content={<Teleneurology />}
        path={Routes.Teleneurology}
      />
      <ProtectedPatientRoute
        content={<TherapiesAndTrials />}
        path={Routes.TherapiesAndTrials}
      />

      <Route path={Routes.Login} component={Login} />
      <Route path={[Routes.Root, Routes.Onboarding]} component={Onboarding} />
    </Switch>
  );
};

const ProtectedPatientRoute: FC<
  { content: React.ReactElement } & Partial<
    ComponentPropsWithoutRef<typeof ProtectedRoute>
  >
> = ({ content, ...rest }) => (
  <ProtectedRoute {...rest}>
    <PatientLayout>{content}</PatientLayout>
  </ProtectedRoute>
);

export default AppRoutes;
