export enum Routes {
  Root = "/",
  Onboarding = "/onboarding",
  Signup = "/onboarding/signup",
  StateAvailabilityNotSupported = "/onboarding/not-supported",
  Diagnosis = "/onboarding/diagnosis",
  Scheduling = "/onboarding/scheduling",
  Thankyou = "/onboarding/thankyou",
  Home = "/home",
  Messaging = "/messaging",
  Profile = "/profile",
  ALSClinics = "/als_clinics",
  Biomarkers = "/biomarkers",
  ClinicalTrials = "/clinical_trials",
  ClinicalTrialsForPD = "/clinical_trials_pd",
  Genetics = "/genetics",
  GeneticsForPD = "/genetics_pd",
  InsuranceNavigation = "/insurance_navigation",
  MedicalRecords = "/medical_records",
  Nutraceutical = "/nutraceutical",
  NutraceuticalForPD = "/nutraceutical_pd",
  Teleneurology = "/teleneurology",
  TherapiesAndTrials = "/therapies_and_trials",
  Dashboard = "/dashboard",
  Login = "/login",
  SignupDiagnosis = "/onboarding/state-availability",
  MovementDisordersClinics = "/movement_disorders_clinics",
}
